import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useGetUserKeyEvents } from '../services/hooks/useGetUserKeyEvents';

type EntryType = {
  event_key: string;
  details: string;
};

const columns: ColumnsType<EntryType> = [
  {
    title: 'Event Key',
    key: 'event_key',
    dataIndex: 'event_key',
  },
  {
    title: 'Details',
    key: 'details',
    dataIndex: 'details',
    render: (details: string) => {
      return <pre>{details}</pre>;
    },
  },
];

const useUserKeyEventsTable = (idUser: number, enabled: boolean) => {
  const { data, isLoading } = useGetUserKeyEvents({
    enabled,
    params: { idUser },
  });

  const dataSource = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.userKeyEvents;
  }, [data]);

  return {
    columns,
    dataSource,
    isLoading,
  };
};

export default useUserKeyEventsTable;
