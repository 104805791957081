import { UseQueryResult, useQuery } from 'react-query';
import { GetUserKeyEventsResponse } from '@cbinsights/adminservice/adminservice';
import { GetUserKeyEvents } from '.';
import { getUserKeyEvents } from '../api/GetUserKeyEvents';

type Params = Parameters<typeof getUserKeyEvents>[0];
type Return = GetUserKeyEventsResponse;

export const useGetUserKeyEvents = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetUserKeyEvents, Params]>(
    ['GetUserKeyEvents', params],
    () => getUserKeyEvents(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
