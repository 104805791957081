import React from 'react';
import { RouteObject } from 'react-router-dom';

const SimpleQueue = React.lazy(() => import('./SimpleQueue'));

export const simpleQueueRoutes: RouteObject = {
  path: '/simple-queue',
  element: (
    <React.Suspense fallback={undefined}>
      <SimpleQueue />
    </React.Suspense>
  ),
};
