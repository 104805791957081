// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.3
//   protoc               v5.28.2
// source: cbinsights/thirdpartyadminservice/thirdpartyadminservice.proto

/* eslint-disable */

export const protobufPackage = "thirdpartyadminservice";

export enum ApprovalStatus {
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  PENDING_REJECTION = "PENDING_REJECTION",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
  INITIAL_CHECK = "INITIAL_CHECK",
  PENDING_QA_APPROVAL = "PENDING_QA_APPROVAL",
  PENDING_QA_REJECTION = "PENDING_QA_REJECTION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum AdminPage {
  UNKNOWN_ADMIN_PAGE = "UNKNOWN_ADMIN_PAGE",
  PROFILE_ADMIN_PAGE = "PROFILE_ADMIN_PAGE",
  VALUATION_ADMIN_PAGE = "VALUATION_ADMIN_PAGE",
  PERSON_ADMIN_PAGE = "PERSON_ADMIN_PAGE",
  SERVICE_PROVIDER_ADMIN_PAGE = "SERVICE_PROVIDER_ADMIN_PAGE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** The enum should match the id in the database */
export enum SourceType {
  UNKNOWN_SOURCE_TYPE = "UNKNOWN_SOURCE_TYPE",
  INVESTOR_PROFILE = "INVESTOR_PROFILE",
  BUSINESS_RELATIONSHIP = "BUSINESS_RELATIONSHIP",
  TOP_SEARCH = "TOP_SEARCH",
  INVESTOR_WEBSITE = "INVESTOR_WEBSITE",
  DATA_REQUEST = "DATA_REQUEST",
  CLIENT_REQUEST = "CLIENT_REQUEST",
  REVENUE = "REVENUE",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  BR_PLAYER_ID = "BR_PLAYER_ID",
  QUALTRICS = "QUALTRICS",
  GEO_COVERAGE = "GEO_COVERAGE",
  PERSON_ADMIN = "PERSON_ADMIN",
  CHROME_EXTENSION = "CHROME_EXTENSION",
  TOP_SEARCH_CBI_EMPLOYEE = "TOP_SEARCH_CBI_EMPLOYEE",
  API_ORG_SEARCH = "API_ORG_SEARCH",
  COLLECTIONS_URL_UPLOADS = "COLLECTIONS_URL_UPLOADS",
  COLLECTIONS_URL_CBI_EMPLOYEE = "COLLECTIONS_URL_CBI_EMPLOYEE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum StockClass {
  UNKNOWN_STOCK_CLASS = "UNKNOWN_STOCK_CLASS",
  COMMON = "COMMON",
  COMMON_WITH_PAR = "COMMON_WITH_PAR",
  COMMON_NO_PAR = "COMMON_NO_PAR",
  PREFERRED = "PREFERRED",
  PREFERRED_WITH_PAR = "PREFERRED_WITH_PAR",
  PREFERRED_NO_PAR = "PREFERRED_NO_PAR",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum DividendPreference {
  UNKNOWN_DIVIDEND_PREFERENCE = "UNKNOWN_DIVIDEND_PREFERENCE",
  CUMULATIVE = "CUMULATIVE",
  NON_CUMULATIVE = "NON_CUMULATIVE",
  NA_DIVIDEND_PREFERENCE = "NA_DIVIDEND_PREFERENCE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum LiquidationPreference {
  UNKNOWN_LIQUIDATION_PREFERENCE = "UNKNOWN_LIQUIDATION_PREFERENCE",
  SENIOR = "SENIOR",
  JUNIOR = "JUNIOR",
  PARI_PASSU = "PARI_PASSU",
  TIERED = "TIERED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum ParticipationRights {
  UNKNOWN_PARTICIPATION_RIGHTS = "UNKNOWN_PARTICIPATION_RIGHTS",
  NO_PARTICIPATION = "NO_PARTICIPATION",
  FULL_PARTICIPATION = "FULL_PARTICIPATION",
  CAPPED_PARTICIPATION = "CAPPED_PARTICIPATION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum SoftwareCategories {
  UNKNOWN_CATEGORY = "UNKNOWN_CATEGORY",
  MOBILE = "MOBILE",
  CLOUD = "CLOUD",
  BOTH = "BOTH",
  NEITHER = "NEITHER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum AntiDilution {
  UNKNOWN_ANTI_DILUTION = "UNKNOWN_ANTI_DILUTION",
  FULL_RATCHET = "FULL_RATCHET",
  WEIGHTED_AVERAGE = "WEIGHTED_AVERAGE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum AmountFormat {
  UNKNOWN_FORMAT = "UNKNOWN_FORMAT",
  DOLLAR = "DOLLAR",
  PERCENTAGE = "PERCENTAGE",
  NA_AMOUNT_FORMAT = "NA_AMOUNT_FORMAT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum DocumentType {
  UNKNOWN_DOCUMENT_TYPE = "UNKNOWN_DOCUMENT_TYPE",
  ANNUAL_REPORT_DOCUMENT_TYPE = "ANNUAL_REPORT_DOCUMENT_TYPE",
  COI_DOCUMENT_TYPE = "COI_DOCUMENT_TYPE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface Empty {
}

export interface Success {
  success: boolean;
}

export interface DeleteDuplicateProfilesRequest {
  urls: string[];
  idCbiEntities: number[];
}

export interface GetReviewersRequest {
  startDate: string;
  endDate: string;
  adminPage: AdminPage;
}

export interface Profile {
  idProfile: number;
  profileStatus: ApprovalStatus;
  name: string;
  description: string;
  urlInfo: UrlInfo | undefined;
  email: string;
  address: Address | undefined;
  taxonomy: Taxonomy | undefined;
  foundedYear: number;
  aliases: ProfileAlias[];
  sourceUrl: string;
  reviewNote: ReviewNote | undefined;
  vendorCorrectness: VendorCorrectness | undefined;
  idCbiEntity: number;
  companyLogo: CompanyLogo | undefined;
  lastModifiedBy: User | undefined;
  tsUpdate: string;
  sourceData: SourceData[];
  qaNote: ReviewNote | undefined;
  externalReviewer: ReviewerWithTime | undefined;
  externalQAManager: ReviewerWithTime | undefined;
}

export interface ReviewerWithTime {
  reviewer: User | undefined;
  tsReview: string;
}

export interface CompanyLogo {
  image: Uint8Array;
  name: string;
  url: string;
  idLogo: number;
  isDeleted: boolean;
}

export interface UrlInfo {
  primaryUrl: string;
  alternateUrls: AlternateUrl[];
}

export interface AlternateUrl {
  idAlternateUrl: number;
  url: string;
  toDelete: boolean;
}

export interface Address {
  idContinent: number;
  idCountry: number;
  country: string;
  idState: number;
  state: string;
  idCity: number;
  city: string;
  street1: string;
  street2: string;
  zip: string;
}

export interface ProfileAlias {
  idProfileAlias: number;
  alias: string;
  aliasType: number;
  toDelete: boolean;
}

export interface ReviewNote {
  idReviewNote: number;
  note: string;
  reviewer: User | undefined;
  tsInsert: string;
}

export interface VendorCorrectness {
  idVendorCorrectness: number;
  isCorrect?: boolean | undefined;
  reason: string;
}

export interface SourceData {
  sourceType: SourceType;
  idSource: number;
}

export interface User {
  idUser: number;
  firstName: string;
  lastName: string;
}

export interface Taxonomy {
  idSector: number;
  idIndustry: number;
  idSubIndustry: number;
  softwareCategory: SoftwareCategories;
  sector: string;
  industry: string;
  subIndustry: string;
}

export interface GetProfilesRequest {
  /** Maintains profiles currently locked by user (if <= the limit) */
  profileStatuses: ApprovalStatus[];
  limit: number;
  offset: number;
  idProfileSearchTerm?: number | undefined;
  nameSearchTerm?: string | undefined;
  urlSearchTerm?:
    | string
    | undefined;
  /** Unlocks all profiles currently locked by user */
  profileStatusesFilter: ApprovalStatus[];
  /** Unlocks all profiles currently locked by user */
  reviewersFilter: number[];
  /** Unlocks all profiles currently locked by user */
  highPerformersOnly: boolean;
}

export interface GetProfilesResponse {
  profiles: Profile[];
}

export interface CreateProfileRequest {
  profile: Profile | undefined;
  profileLockedByIdUser: number;
}

export interface DeleteTestProfileRequest {
  idProfile: number;
}

export interface GetProfilesCountsResponse {
  needsReviewCount: number;
  pendingCount: number;
}

export interface UpdateProfilesRequest {
  profileStatus: ApprovalStatus;
  profiles: Profile[];
}

export interface UpdateProfilesResponse {
  succeededProfiles: Profile[];
  failedProfiles: Profile[];
  failedProfileReasons: { [key: number]: string };
}

export interface UpdateProfilesResponse_FailedProfileReasonsEntry {
  key: number;
  value: string;
}

export interface DetectDuplicateUrlsRequest {
  urls: string[];
  profileStatus: ApprovalStatus;
  /** checks both full url and url's domain only */
  includeDomainOnly: boolean;
  includeAllNonFinalizedStatuses: boolean;
}

export interface DetectDuplicateUrlsResponse {
  /** URL => DuplicateIds */
  profileIdMaps: { [key: string]: DetectDuplicateUrlsResponse_DuplicateIds };
}

export interface DetectDuplicateUrlsResponse_DuplicateIds {
  idProfiles: number[];
  idCbiEntities: number[];
}

export interface DetectDuplicateUrlsResponse_ProfileIdMapsEntry {
  key: string;
  value: DetectDuplicateUrlsResponse_DuplicateIds | undefined;
}

export interface BatchCreateProfilesRequest {
  profiles: CreateProfileRequest[];
}

export interface BatchCreateProfilesResponse {
  newProfiles: number[];
  failureReason: string;
}

export interface GenerateAuditReportRequest {
  idExternalReviewers: number[];
  idExternalQAManagers: number[];
  startDate: string;
  endDate: string;
  generateSummaryReport: boolean;
}

export interface GenerateAuditReportResponse {
  userAuditInfo: UserAuditInfo[];
}

export interface UserAuditInfo {
  idUser: number;
  individualReports: IndividualReportInfo[];
  summaryReport: SummaryAuditInfoProfile | undefined;
  email: string;
}

export interface SummaryAuditInfoProfile {
  totalNumberSubmittedByEU: number;
  totalNumberReviewed: number;
  totalNumberOfCorrectRecords: number;
  totalNumberOfIncorrectRecords: number;
  percentMarkedCorrectly: number;
  totalNumberSubmittedByQA: number;
}

export interface IndividualReportInfo {
  idProfile: number;
  externalReviewerSubmission: Profile | undefined;
  externalReviewerQAManagerSubmission: Profile | undefined;
  internalReviewerCorrection: Profile | undefined;
}

export interface IdAnnualReports {
  idAnnualReports: number[];
  errorMessage: string;
}

export interface IdCOIs {
  idCOIs: number[];
  errorMessage: string;
}

export interface AnnualReport {
  idAnnualReport: number;
  idCbiEntity: number;
  approvalStatus: ApprovalStatus;
  fiscalYear: number;
  fileUrl: string;
  highPriority?: boolean | undefined;
  reviewNote: string;
  reviewer: User | undefined;
  legalName: string;
  entityName: string;
  vendorCorrectness: VendorCorrectness | undefined;
  approver: User | undefined;
  annualReportStocks: AnnualReportStock[];
  annualReportTotalStocks: AnnualReportTotalStock[];
}

export interface AnnualReportStock {
  idAnnualReportStock: number;
  toDelete: boolean;
  stockClass?: StockClass | undefined;
  authorizedClassVolume?: number | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  classParValue?: number | undefined;
  totalClassIssuedVolume?: number | undefined;
}

export interface AnnualReportTotalStock {
  idAnnualReportTotalStock: number;
  toDelete: boolean;
  totalIssuedOutstandingStockVolume?: number | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
}

export interface CreateAnnualReportRequest {
  annualReport: AnnualReport | undefined;
  recordLockedByIdUser: number;
}

export interface BatchCreateAnnualReportsRequest {
  annualReports: AnnualReport[];
}

export interface DeleteAnnualReportRequest {
  idAnnualReport: number;
}

export interface COI {
  idCOI: number;
  idCbiEntity: number;
  approvalStatus: ApprovalStatus;
  filingDate: string;
  fileUrl: string;
  authorizedCommonStockVolume?: number | undefined;
  authorizedPreferredStockVolume?: number | undefined;
  commonStockParValue?: number | undefined;
  preferredStockParValue?: number | undefined;
  authorizedEmployeeStockOptions?: number | undefined;
  fundingRounds: COIFundingRound[];
  authorizedCommonStocksSource: string;
  authorizedPreferredStocksSource: string;
  roundSpecificStocksSource: string;
  originalIssuePricesSource: string;
  dividendPreferencesSource: string;
  dividendAmountsSource: string;
  liquidationPreferencesSource: string;
  participationRightsSource: string;
  conversionPricesSource: string;
  antiDilutionsSource: string;
  authorizedEmployeeStockOptionsSource: string;
  highPriority?: boolean | undefined;
  reviewNote: string;
  reviewer: User | undefined;
  legalName: string;
  entityName: string;
  vendorCorrectness: VendorCorrectness | undefined;
  generalVotingRightsSource: string;
  boardVotingRightsSource: string;
  approver: User | undefined;
  liquidationPreference?: LiquidationPreference | undefined;
}

export interface COIFundingRound {
  idCOIFundingRound: number;
  /** to be removed later */
  idCOIFundingRoundParent: number;
  idRoundType?: number | undefined;
  roundType?: string | undefined;
  preferredStockVolume?: number | undefined;
  originalIssuePrice?: number | undefined;
  conversionPrice?: number | undefined;
  dividendAmount?: number | undefined;
  liquidationAmount?: number | undefined;
  liquidationCap?: number | undefined;
  dividendPreference?: DividendPreference | undefined;
  participationRights?: ParticipationRights | undefined;
  antiDilution?: AntiDilution | undefined;
  toDelete: boolean;
  dividendAmountFormat?: AmountFormat | undefined;
  hasGeneralVotingRights?: boolean | undefined;
  hasBoardVotingRights?: boolean | undefined;
  idGroup?: number | undefined;
  orderIndex: number;
  idRoundTypeParent: number;
  /** we need this field for valuation sevice for grouped round even though this seems to be duplicate info */
  intendedAmount?: number | undefined;
  manuallyGrouped: boolean;
}

export interface CreateCOIRequest {
  coi: COI | undefined;
  recordLockedByIdUser: number;
}

export interface BatchCreateCOIsRequest {
  cois: COI[];
}

export interface DeleteCOIRequest {
  idCOI: number;
}

export interface Document {
  annualReport?: AnnualReport | undefined;
  coi?: COI | undefined;
}

export interface GetAnnualReportRequest {
  idAnnualReport: number;
}

export interface GetCOIRequest {
  idCOI: number;
}

export interface UpdateAnnualReportRequest {
  annualReport: AnnualReport | undefined;
}

export interface UpdateCOIRequest {
  coi: COI | undefined;
}

export interface GetDocumentCountsResponse {
  needsReviewCount: number;
  pendingCount: number;
}

export interface GenerateValuationAuditReportRequest {
  idExternalReviewers: number[];
  startDate: string;
  endDate: string;
  generateAnnualReportSummaryReport: boolean;
  generateCOISummaryReport: boolean;
  generateAnnualReportIndividualReport: boolean;
  generateCOIIndividualReport: boolean;
}

export interface GenerateValuationAuditReportResponse {
  userAuditInfo: ValuationUserAuditInfo[];
}

export interface SummaryAuditInfo {
  totalNumberSubmitted: number;
  totalNumberReviewed: number;
  totalNumberOfCorrectRecords: number;
  totalNumberOfIncorrectRecords: number;
  percentMarkedCorrectly: number;
}

export interface ValuationUserAuditInfo {
  idUser: number;
  email: string;
  annualReportSummaryReport: SummaryAuditInfo | undefined;
  coiSummaryReport: SummaryAuditInfo | undefined;
  annualReportIndividualReports: AnnualReportIndividualReport[];
  coiIndividualReports: COIIndividualReport[];
}

export interface AnnualReportIndividualReport {
  idAnnualReport: number;
  externalReviewerSubmission: AnnualReport | undefined;
  internalReviewerCorrection: AnnualReport | undefined;
}

export interface COIIndividualReport {
  idCOI: number;
  externalReviewerSubmission: COI | undefined;
  internalReviewerCorrection: COI | undefined;
}

export interface DocumentId {
  idDocument: number;
  documentType: DocumentType;
}

export interface BatchUpdateDocumentPriorityRequest {
  documentIds: DocumentId[];
  highPriority: boolean;
}

export interface ListDocumentRecordsRequest {
  documentTypeFilter: DocumentType;
  approvalStatusesFilter: ApprovalStatus[];
  reviewersFilter: number[];
  highPriorityFilter?: boolean | undefined;
  startFilingDateFilter: string;
  endFilingDateFilter: string;
  idRecordSearchTerm?: number | undefined;
  idCbiEntitySearchTerm?: number | undefined;
  limit: number;
  fetchLockedRecords: boolean;
}

export interface BatchUpdateDocumentStatusRequest {
  documentIds: DocumentId[];
  approvalStatus: ApprovalStatus;
}

export interface BatchUpdateDocumentStatusResponse {
  totalNumberSubmitted: number;
  totalNumberUpdated: number;
}

export interface ListDocumentRecordsResponse {
  items: ListDocumentRecordItem[];
}

export interface GetSuggestedCOIFundingRoundGroupingsRequest {
  fundingRounds: COIFundingRound[];
  idCbiEntity: number;
}

export interface GetSuggestedCOIFundingRoundGroupingsResponse {
  fundingRounds: COIFundingRound[];
}

export interface CreatePersonDraftRequest {
  personDraft: PersonDraft | undefined;
}

export interface DeletePersonDraftRequest {
  idPersonDraft: number;
}

export interface ListDocumentRecordItem {
  idDocument: number;
  documentType: DocumentType;
  filingDate: string;
  legalName: string;
  approvalStatus: ApprovalStatus;
  reviewer: User | undefined;
  approver: User | undefined;
  highPriority: boolean;
}

export interface PersonDraft {
  idPersonDraft: number;
  idEntityPerson: number;
  firstName: string;
  middleName: string;
  email: string;
  lastName: string;
  bio: string;
  bioSource: string;
  linkedin: string;
  twitter: string;
  workExperience: WorkExperienceDraft[];
  boardAssociations: BoardAssociationDraft[];
  education: EducationDraft[];
  toDelete: boolean;
  /** cbi_data.source_master */
  idSources: number[];
}

export interface PersonTitle {
  idPersonTitle: number;
  title: string;
  titleCode: string;
}

export interface RoundInfo {
  idRound: number;
  round: string;
}

export interface FundingRound {
  idFunding: number;
  idRound: number;
  name: string;
  toDelete: boolean;
}

export interface WorkExperienceDraft {
  idWorkExperience: number;
  idCbiEntity: number;
  entityName: string;
  title: PersonTitle | undefined;
  function: string;
  current: boolean;
  startDate: string;
  endDate: string;
  toDelete: boolean;
}

export interface BoardAssociationDraft {
  idBoardAssociation: number;
  idCbiEntity: number;
  entityName: string;
  title: PersonTitle | undefined;
  current: boolean;
  startDate: string;
  endDate: string;
  rounds: FundingRound[];
  toDelete: boolean;
}

export interface School {
  idSchool: number;
  school: string;
}

export interface Degree {
  idDegree: number;
  degree: string;
}

export interface FieldOfStudy {
  idFieldOfStudy: number;
  fieldOfStudy: string;
}

export interface EducationDraft {
  idEntityPersonEducation: number;
  school: School | undefined;
  degree: Degree | undefined;
  fieldOfStudy: FieldOfStudy | undefined;
  toDelete: boolean;
  graduationYear: number;
}

export interface GeneratePersonAuditReportRequest {
  idExternalReviewers: number[];
  startDate: string;
  endDate: string;
  generateSummaryReport: boolean;
  generateIndividualReports: boolean;
}

export interface GeneratePersonAuditReportResponse {
  userAuditInfo: PersonUserAuditInfo[];
  summaryReport: PersonAuditSummaryReport | undefined;
}

export interface PersonUserAuditInfo {
  idUser: number;
  firstName: string;
  lastName: string;
  individualReports: PersonDraft[];
  individualSummaryReport: PersonAuditSummaryReport | undefined;
}

export interface PersonAuditSummaryReport {
  entitiesTouched: number;
  personProfilesTouched: number;
  personProfilesAdded: number;
  /** only count updates if they were not added in the audit window */
  personProfilesUpdated: number;
  personProfilesDeleted: number;
  /** only count field additions that were not later deleted in the audit window */
  fieldCoverageBio: number;
  fieldCoverageLinkedin: number;
  fieldCoverageTwitter: number;
  fieldCoverageCurrentWorkExperienceTitle: number;
  fieldCoverageFormerWorkExperienceTitle: number;
  fieldCoverageBoardSeat: number;
  fieldCoverageEducation: number;
}

export interface ServiceProviderDraft {
  idServiceProviderDraft: number;
  idServiceProvider: number;
  idCbiEntity: number;
  serviceProviderType: ServiceProviderType | undefined;
  approvalStatus: ApprovalStatus;
  toDelete: boolean;
}

export interface ServiceProviderType {
  idServiceProviderType: number;
  serviceProviderType: string;
}

export interface ServiceProviderRelationshipDraft {
  idServiceProviderRelationshipDraft: number;
  idServiceProviderRelationship: number;
  idCbiEntityServiceProvider: number;
  idCbiEntityClient: number;
  entityNameServiceProvider: string;
  entityNameClient: string;
  associatedTransaction: ServiceProviderTransaction | undefined;
  source: string;
  approvalStatus: ApprovalStatus;
  toDelete: boolean;
  idServiceProviderDraft: number;
  idServiceProvider: number;
  serviceProviderRelationshipServiceTypeDrafts: ServiceProviderRelationshipServiceTypeDraft[];
  serviceProviderType: ServiceProviderType | undefined;
}

export interface ServiceProviderRelationshipServiceTypeDraft {
  idServiceProviderRelationshipServiceTypeDraft: number;
  idServiceProviderRelationshipServiceType: number;
  serviceType: ServiceType | undefined;
  toDelete: boolean;
}

export interface ServiceType {
  idServiceType: number;
  serviceType: string;
}

export interface ServiceProviderTransaction {
  idCbiEntity: number;
  entityName: string;
  fundingRound: FundingRound | undefined;
  isSell: boolean;
  exitIndicator: boolean;
}

export interface CreateServiceProviderDraftRequest {
  serviceProviderDraft: ServiceProviderDraft | undefined;
}

export interface DeleteServiceProviderDraftRequest {
  idServiceProviderDraft: number;
}

export interface CreateServiceProviderRelationshipDraftRequest {
  serviceProviderRelationshipDraft: ServiceProviderRelationshipDraft | undefined;
}

export interface DeleteServiceProviderRelationshipDraftRequest {
  idServiceProviderRelationshipDraft: number;
}

export interface GenerateServiceProviderAuditReportRequest {
  idExternalReviewers: number[];
  startDate: string;
  endDate: string;
  generateSummaryReport: boolean;
  generateIndividualReports: boolean;
}

export interface GenerateServiceProviderAuditReportResponse {
  userAuditInfo: ServiceProviderUserAuditInfo[];
  summaryReport: ServiceProviderAuditSummaryReport | undefined;
}

export interface ServiceProviderUserAuditInfo {
  idUser: number;
  firstName: string;
  lastName: string;
  individualReports: ServiceProviderRelationshipDraft[];
  individualSummaryReport: ServiceProviderAuditSummaryReport | undefined;
}

export interface ServiceProviderAuditSummaryReport {
  totalServiceProviderRelationshipsAdded: number;
  totalServiceProviderRelationshipsUpdated: number;
  totalServiceProviderRelationshipsDeleted: number;
  totalUniqueProvidersWithRelationshipsAdded: number;
}

export interface AllUserInfo {
  userInfo: UserInfo[];
}

export interface UserInfo {
  idUser: number;
  email: string;
  firstName: string;
  lastName: string;
  idPackage: number;
  isActive: boolean;
  phone: string;
  title: string;
  idRole: number;
}

export interface FundingIds {
  idCbiEntity: number;
  idFundings: number[];
  idFundingDrafts: string[];
}
