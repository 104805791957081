import React from 'react';
import { Col, Progress, Row, Tooltip } from 'antd';
import {
  FEATURE_IDS,
  NEW_FEATURE_IDS,
} from 'client/modules/team/constants/features';
import { useGetUserFeatureUsage } from '../services/hooks/useGetUserFeatureUsage';
import UpdateFeatureUsage from './UpdateFeatureUsage';
import { Feature, getProgressInformation } from '../utils/progressInformation';

type Props = {
  idUser: number;
  teamId?: number;
};

const FeatureUsage = ({ idUser, teamId }: Props) => {
  const { data, refetch: refetchFeatureUsage } = useGetUserFeatureUsage({
    enabled: Boolean(idUser),
    params: {
      id_user: idUser,
      id_features: [
        FEATURE_IDS.profiles,
        FEATURE_IDS.downloads,
        FEATURE_IDS.vendorConnects,
        NEW_FEATURE_IDS.researchReportDownload,
        FEATURE_IDS.companyProfiles,
        FEATURE_IDS.investorProfiles,
      ],
    },
  });

  const features = React.useMemo(() => {
    if (!data?.user_feature_usages) {
      return [];
    }

    return data.user_feature_usages.map<Feature>((feature) => {
      return {
        id: feature.id_feature,
        name: feature.feature_name,
        usage: feature.usage,
        limit: feature.quota,
        description: '',
        packageLimit: feature.package_limit,
      };
    });
  }, [data]);

  return (
    <Row gutter={[40, 16]} className="mt-3 pl-5">
      {features.map((feature) => {
        const progressInfo = getProgressInformation(feature);
        return (
          <Col xl={12} xs={24} key={feature.id}>
            <Tooltip title={feature.description}>
              <div className="font-semibold capitalize">
                {feature.name}
                <UpdateFeatureUsage
                  featureName={feature.name}
                  idFeature={feature.id}
                  usage={feature.usage}
                  userLimit={feature.limit}
                  userId={idUser}
                  teamId={teamId}
                  refetchFeatureUsage={refetchFeatureUsage}
                  isVendorConnect={feature.id === FEATURE_IDS.vendorConnects}
                />
              </div>
            </Tooltip>
            <Tooltip title={progressInfo.usage}>
              <Progress
                percent={progressInfo.percent}
                status={progressInfo.status}
              />
            </Tooltip>
            <div
              className={`flex justify-between pr-[15px] ${progressInfo.colorClassName} mt-2`}
            >
              <span>{progressInfo.usage}</span>
              <div className="flex flex-col items-end">
                <span>{`Package Limit: ${feature.packageLimit}`}</span>
                {feature.limit ? (
                  <span>{`User Limit: ${feature.limit}`}</span>
                ) : null}
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default FeatureUsage;
