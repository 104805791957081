import {
  GetUserKeyEventsRequest,
  GetUserKeyEventsResponse,
} from '@cbinsights/adminservice/adminservice';
import { request } from 'client/modules/common/utils/request';

export const getUserKeyEvents = (
  req: Partial<GetUserKeyEventsRequest>
): Promise<GetUserKeyEventsResponse> =>
  request({
    url: '/service/adminservice/GetUserKeyEvents',
    body: req,
  }).then((r) => r.body);
