import React, { useMemo } from 'react';
import { Col, Progress, Row, Tooltip } from 'antd';
import {
  Feature,
  getProgressInformation,
} from 'client/modules/users/utils/progressInformation';
import { FEATURE_IDS, NEW_FEATURE_IDS } from '../../constants/features';
import { useGetTeamFeatureUsage } from '../../services/hooks/useGetTeamFeatureUsage';
import UpdateFeatureUsage from './UpdateFeatureUsage';

type Props = {
  idTeam: number;
};

const FeatureUsage = ({ idTeam }: Props) => {
  const { data: teamFeatureUsage, refetch: refetchTeamFeatureUsage } =
    useGetTeamFeatureUsage({
      enabled: Boolean(idTeam),
      params: { id_team: idTeam },
    });

  const features: Array<Feature> = useMemo(() => {
    if (!teamFeatureUsage?.team_feature_usages) {
      return [];
    }

    const TEAM_FEATURES = {
      [FEATURE_IDS.downloads]: {
        name: 'Downloads',
        id: FEATURE_IDS.downloads,
      },
      [NEW_FEATURE_IDS.researchReportDownload]: {
        name: 'Research PDF Download',
        id: FEATURE_IDS.downloads,
      },
      [FEATURE_IDS.profiles]: {
        name: 'Profiles',
        id: FEATURE_IDS.profiles,
      },
    };

    const result: Array<Feature> = teamFeatureUsage.team_feature_usages.reduce<
      Array<Feature>
    >((accFeature, teamFeature) => {
      const featureLabels = TEAM_FEATURES[teamFeature.id_feature];

      if (featureLabels) {
        return [
          ...accFeature,
          {
            id: teamFeature.id_feature,
            limit: teamFeature.package_quota,
            packageLimit: teamFeature.package_quota,
            usage: teamFeature.total,
            additionalQuota: teamFeature.additional_quota,
            name: featureLabels?.name,
          },
        ];
      }

      return accFeature;
    }, []);

    return result;
  }, [teamFeatureUsage]);

  return (
    <Row className="pt-8 mb-10" gutter={[25, 25]}>
      {features.map((feature) => {
        const progressInfo = getProgressInformation(feature);
        return (
          <Col xl={12} xs={24} key={feature.id}>
            <Tooltip title={feature.description}>
              <div className="font-semibold capitalize">
                {feature.name}
                <UpdateFeatureUsage
                  fields={{
                    additionalQuota: feature.additionalQuota,
                    usage: feature.usage,
                  }}
                  idFeature={feature.id}
                  refetchTeamFeatureUsage={refetchTeamFeatureUsage}
                  idTeam={idTeam}
                  packageLimit={feature.packageLimit}
                />
              </div>
            </Tooltip>
            <Tooltip title={progressInfo.usage}>
              <Progress
                percent={progressInfo.percent}
                status={progressInfo.status}
              />
            </Tooltip>
            <div
              className={`flex justify-between pr-[15px] ${progressInfo.colorClassName} mt-2`}
            >
              <span>{progressInfo.usage}</span>
              <div className="flex flex-col items-end">
                <span>{`Package Limit: ${feature.packageLimit}`}</span>
                {feature.additionalQuota ? (
                  <span>{`Additional Quota: ${feature.additionalQuota}`}</span>
                ) : null}
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default FeatureUsage;
